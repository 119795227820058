import { UnitSystem, UnitSymbol } from "../../settings/enum/settings.enum";

function centigradeToFahrenheit(centigrade: number): number {
  return centigrade * 1.8 + 32;
}

export const getTemperatureValue = (
  obj: {
    temperature?: number;
    temperatureFahrenheit?: number | string;
    temperatureCentigrade?: number | string;
  },
  unitSystem: UnitSystem
): number | string | null => {
  if (typeof obj !== "object") {
    return null;
  }
  if (
    unitSystem === UnitSystem.Metric &&
    "temperatureCentigrade" in obj &&
    typeof obj.temperatureCentigrade !== "undefined"
  ) {
    return obj.temperatureCentigrade;
  }

  if (
    "temperatureFahrenheit" in obj &&
    typeof obj.temperatureFahrenheit !== "undefined"
  ) {
    return obj.temperatureFahrenheit;
  }

  if (typeof obj.temperature === "number") {
    return unitSystem === UnitSystem.Metric
      ? obj.temperature
      : centigradeToFahrenheit(obj.temperature);
  }

  return null;
};

export const getTemperatureUnit = (unitSystem: UnitSystem): string => {
  if (unitSystem === UnitSystem.Metric) {
    return UnitSymbol.Celsius;
  }
  return UnitSymbol.Fahrenheit;
};

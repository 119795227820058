import React, { FC, useState } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { FileDownloadOutlined } from "@mui/icons-material";
import { Menu, MenuItem } from "@mui/material";
import { StyledCSVLink } from "./ExportButton.style";
import { useUnitSystem } from "../../../app/hooks/useUnitSystem";
import { getTemperatureValue } from "../../../app/utils/get-temperature-value";
import { getPressureValue } from "../../../app/utils/get-pressure-value";
import { timezoneCode } from "../../../app/utils/timezone-code";
import { getTimeZone } from "../../../app/utils/get-timezone";
import { TireDto } from "../../api/vehicles.api.dto";
import { Button, IButton } from "../../../app/components/Button/Button";

dayjs.extend(localizedFormat);

interface IExportButton extends IButton {
  tires: TireDto[];
  vehicleId: string;
}

export const ExportButton: FC<IExportButton> = ({
  tires,
  vehicleId,
  ...rest
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const unitSystem = useUnitSystem();
  const tz = timezoneCode(getTimeZone());

  const rows = tires.map((tire) => ({
    state: tire.pressureStatus,
    date: dayjs(tire.timestamp).format("L"),
    time: dayjs(tire.timestamp).format(`HH:mm:ss [${tz}]`),
    tireID: `${tire.axle}${tire.tire}`,
    axle: tire.axle,
    tire: tire?.tireLocation,
    temp: getTemperatureValue(tire, unitSystem) ?? "",
    pressure: getPressureValue(tire, unitSystem) ?? "",
    leak: tire.leak,
    tempAlert: tire.temperatureAlert,
  }));

  return (
    <>
      <Button
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        startIcon={<FileDownloadOutlined />}
        {...rest}
      >
        Export
      </Button>
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: -8,
          horizontal: "right",
        }}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem key="csv-export">
          <StyledCSVLink
            data={rows.map((row) => ({
              ...row,
              axle: row.axle + 1,
            }))}
            filename={`${vehicleId}-${dayjs().format("L")}.csv`}
          >
            Export to CSV
          </StyledCSVLink>
        </MenuItem>
      </Menu>
    </>
  );
};
